<template>
  <div>
    <v-dialog
      v-model="dialogDelete"
      max-width="500px"
      @click:outside="closeDelete"
      @keydown="closeDelete"
    >
      <v-card>
        <v-card-text>
          {{ message }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            @click="closeDelete"
          >
            Non
          </v-btn>
          <v-btn
            color="success"
            @click="performAction"
          >
            Oui
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'ConfirmDialog',
  emits: ['clickout', 'submitted'],

  props: {
    dialogDelete: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: 'Voulez-vous effectuer cette opération?',
    },
  },

  methods: {
    closeDelete() {
      this.$emit('clickout')
    },

    performAction() {
      this.$emit('submitted')
    },
  },

}
</script>

<style scoped>

</style>
