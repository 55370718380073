<template>
  <div>
    <v-card class="mb-8">
      <v-alert
        v-model="isAlertVisible"
        :color="error ? 'error' : 'success'"
        dismissible
        text
        transition="slide-y-transition"
      >
        {{ message }}
      </v-alert>
      <div class="m-2">
        <v-row class="ml-5 mr-5">
          <v-col
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="field.reference"
              label="Filtrer par la reférence"
              outlined
              dense
              placeholder="Filtrer par la reférence"
              hide-details="auto"
              :rules="rules.reference"
              clearable
              @input="filterItems"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="field.svc_number"
              label="Filtrer par compte"
              outlined
              dense
              placeholder="Filtrer par compte"
              hide-details="auto"
              :rules="rules.svc_number"
              clearable
              @input="filterItems"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="field.transaction_id"
              label="Filtrer par ID transaction"
              outlined
              dense
              placeholder="Filtrer par ID transaction"
              hide-details="auto"
              :rules="rules.transaction_id"
              clearable
              @input="filterItems"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-select
              v-model="field.op_api_id"
              :items="opApisItems"
              label="Rechercher par moyen de paiment"
              outlined
              dense
              placeholder="Rechercher par moyen de paiment"
              clearable
              hide-details="auto"
              persistent-hint
              single-line
              :rules="rules.op_api_id"
              @input="getPaymentsByFields"
            ></v-select>
          </v-col>
        </v-row>
      </div>
      <div class="m-2">
        <v-row class="ml-5 mr-5">
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="field.base_amount"
              label="Rechercher par montant"
              outlined
              dense
              hide-details="auto"
              placeholder="Rechercher par montant"
              :rules="rules.base_amount"
              clearable
              @input="filterItems"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="field.amount"
              label="Rechercher par montant hors frais"
              outlined
              dense
              hide-details="auto"
              placeholder="'Rechercher par hors frais'"
              :rules="rules.amount"
              clearable
              @input="filterItems"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-select
              v-model="field.payment_type_id"
              :items="paymentTypes"
              item-text="label"
              item-value="id"
              label="Rechercher par type de paiement"
              outlined
              dense
              placeholder="Rechercher par type de paiement"
              clearable
              hide-details="auto"
              persistent-hint
              single-line
              :rules="rules.payment_type_id"
              @input="getPaymentsByFields"
            ></v-select>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <v-card>
      <v-data-table
        :loading="isDialogVisible"
        :headers="headers"
        :items="payments"
        :items-per-page="itemsPerPage"
        :page.sync="currentPage"
        dense
        fixed-header
        height="500"
        hide-default-footer
        @page-count="currentPage = $event"
      >
        <template #[`item.user`]="{item}">
          {{ item.user !== null ? (item.user.name !== null ? item.user.name : item.user.phone_number) : null }}
        </template>
        <template #[`item.road.user`]="{item}">
          {{ item.user !== null ? (item.user.name !== null ? item.user.name : item.user.phone_number) : null }}
        </template>
        <template #[`item.base_amount`]="{item}">
          <v-chip
            small
            color="primary"
          >
            {{ formatAmount(item.base_amount) }}
          </v-chip>
        </template>
        <template #[`item.amount`]="{item}">
          <v-chip
            small
            color="secondary"
          >
            {{ formatAmount(item.amount) }}
          </v-chip>
        </template>
        <template #[`item.payment_type.label`]="{item}">
          <v-chip
            v-if="item.payment_type.code = 'CASHIN'"
            small
            color="primary"
          >
            <v-icon
              class="me-1"
              size="18"
            >
              {{ icons.mdiCashPlus }}
            </v-icon>
            {{ item.payment_type.label }}
          </v-chip>
          <v-chip
            v-else
            small
            color="secondary"
          >
            <v-icon
              class="me-1"
              size="18"
            >
              {{ icons.mdiCashMinus }}
            </v-icon>
            {{ item.payment_type.label }}
          </v-chip>
        </template>

        <template #[`item.created_at`]="{item}">
          {{ formattingDate(`${item.created_at}`) }}
        </template>

        <!-- action -->
        <template #[`item.actions`]="{ item }">
          <buttons-group
            :item="item"
            :remove="false"
            :edit="false"
            :lock="false"
            :status="false"
            @view="openItem(item)"
          />
        </template>
      </v-data-table>

      <v-card-text class="pt-2">
        <v-row>
          <v-col
            lg="2"
            cols="3"
          >
            <v-text-field
              :value="field.paginate"
              label="Lignes par page:"
              type="number"
              min="-1"
              max="15"
              hide-details
              @input="getPaymentsPerPage"
            ></v-text-field>
          </v-col>

          <v-col
            lg="10"
            cols="9"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="currentPage"
              total-visible="6"
              :length="lastPage"
              @input="getPayments"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import LoaderDialog from '@core/components/dialogs/LoaderDialog'
import { mapGetters, mapState } from 'vuex'
import { ref } from '@vue/composition-api/dist/vue-composition-api'
import {
  mdiAccountLock, mdiCashMinus, mdiCashPlus,
  mdiDeleteOutline,
  mdiDotsVertical, mdiEyeOutline, mdiLockOpen,
  mdiPencilOutline, mdiRefresh,
  mdiSquareEditOutline,
} from '@mdi/js'
import { formatDate } from '@core/date/dateFunctions'
import ButtonsGroup from '@core/components/buttons/ButtonsGroup'
import store from '@/store'
import {intToCurrency} from "@core/dynamicComponents/dynamicComponents";

export default {
  name: 'RoadPayment',
  components: { ButtonsGroup, LoaderDialog },

  props: {
    road: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      currentPage: null,
      lastPage: null,
      dialogDelete: false,
      isDialogVisible: false,
      message: null,
      error: false,
      isAlertVisible: false,
      rules: {
        svc_number: [true],
        reference: [true],
        nb_passagers: [true],
        transaction_id: [true],
        base_amount: [true],
        amount: [true],
        payment_type_id: [true],
        op_api_id: [true],
      },
      opApisItems: [],
    }
  },
  computed: {
    ...mapGetters('roadPayment', ['payments', 'current_page', 'last_page', 'per_page', 'opApis', 'paymentTypes']),
    ...mapState('roadPayment', ['field']),
  },

  created() {
    this.getPayments(this.current_page ? this.current_page : null)
    this.getOpApis()
    this.getPaymentTypes()
  },

  methods: {
    formattingDate(date) {
      return formatDate(date)
    },

    deleteItemConfirm() {
      this.closeDelete()
    },

    openItem(item) {
      this.$router.push({ name: 'showpayment', params: { id: item.id } })
    },

    closeDelete() {
      this.dialogDelete = false
    },

    getStartOmsId(event) {
      this.field.base_amount = event ? event.toString() : null
      this.getPaymentsByFields()
    },

    getEndOmsId(event) {
      this.field.amount = event ? event.toString() : null
      this.getPaymentsByFields()
    },

    getOpApis() {
      store.dispatch('roadPayment/getOpApis').then(() => {
        this.opApis.forEach(elt => {
          this.opApisItems.push({ text: elt.operator.label, value: elt.id })
        })
      }).catch(error => {
        console.log(error.response.data.message)
      })
    },

    getPaymentTypes() {
      store.dispatch('roadPayment/getpaymentTypes').catch(error => {
        console.log(error.response.data.message)
      })
    },

    getPayments(page = 1) {
      this.isDialogVisible = true
      this.field.road_id = this.road.id
      this.field.user_id = null
      store.dispatch('roadPayment/getPaymentsList', { page, field: this.field }).then(() => {
        this.currentPage = this.current_page
        this.lastPage = this.last_page
        this.isDialogVisible = false
        this.isAlertVisible = false
        Object.keys(this.rules).forEach(key => {
          this.rules[key] = [true]
        })
      }).catch(error => {
        this.message = error.response.data.message
        if (error.response.data.errors !== undefined) {
          this.rules.svc_number = error.response.data.errors.svc_number !== undefined ? error.response.data.errors.svc_number : [true]
          this.rules.reference = error.response.data.errors.reference !== undefined ? error.response.data.errors.reference : [true]
          this.rules.nb_passagers = error.response.data.errors.nb_passagers !== undefined ? error.response.data.errors.nb_passagers : [true]
          this.rules.transaction_id = error.response.data.errors.transaction_id !== undefined ? error.response.data.errors.transaction_id : [true]
          this.rules.payment_type_id = error.response.data.errors.payment_type_id !== undefined ? error.response.data.errors.payment_type_id : true
          this.rules.base_amount = error.response.data.errors.base_amount !== undefined ? error.response.data.errors.base_amount : true
          this.rules.amount = error.response.data.errors.amount !== undefined ? error.response.data.errors.amount : [true]
          this.rules.op_api_id = error.response.data.errors.op_api_id !== undefined ? error.response.data.errors.op_api_id : [true]
        }
        this.error = true
        this.isAlertVisible = true
      })
    },

    // eslint-disable-next-line camelcase
    getPaymentsPerPage(per_page) {
      // eslint-disable-next-line camelcase
      this.field.road_id = this.road.id
      this.field.user_id = null
      if (per_page !== null) {
        this.isDialogVisible = true
        // eslint-disable-next-line camelcase
        this.field.paginate = per_page
        this.itemsPerPage = parseInt(per_page, 10)
        store.dispatch('roadPayment/getPaymentsList', {
          page: this.currentPage,
          field: this.field,
        }).then(() => {
          this.currentPage = this.current_page
          this.lastPage = this.last_page
          this.isDialogVisible = false
          this.isAlertVisible = false
          Object.keys(this.rules).forEach(key => {
            this.rules[key] = [true]
          })
        })
      }
    },

    filterItems() {
      setTimeout(() => {
        this.getPaymentsByFields()
      }, 1500)
    },

    getPaymentsByFields() {
      this.isDialogVisible = true
      this.field.road_id = this.road.id
      this.field.user_id = null
      store.dispatch('roadPayment/getPaymentsList', {
        page: this.currentPage,
        field: this.field,
      }).then(() => {
        this.currentPage = this.current_page
        this.lastPage = this.last_page
        this.isDialogVisible = false
        this.isAlertVisible = false
        this.error = false
        this.isAlertVisible = false
        Object.keys(this.rules).forEach(key => {
          this.rules[key] = [true]
        })
      }).catch(error => {
        this.message = error.response.data.message
        if (error.response.data.errors !== undefined) {
          this.rules.svc_number = error.response.data.errors.svc_number !== undefined ? error.response.data.errors.svc_number : [true]
          this.rules.reference = error.response.data.errors.reference !== undefined ? error.response.data.errors.reference : [true]
          this.rules.nb_passagers = error.response.data.errors.nb_passagers !== undefined ? error.response.data.errors.nb_passagers : [true]
          this.rules.transaction_id = error.response.data.errors.transaction_id !== undefined ? error.response.data.errors.transaction_id : [true]
          this.rules.payment_type_id = error.response.data.errors.payment_type_id !== undefined ? error.response.data.errors.payment_type_id : [true]
          this.rules.base_amount = error.response.data.errors.base_amount !== undefined ? error.response.data.errors.base_amount : [true]
          this.rules.amount = error.response.data.errors.amount !== undefined ? error.response.data.errors.amount : [true]
          this.rules.op_api_id = error.response.data.errors.op_api_id !== undefined ? error.response.data.errors.op_api_id : [true]
        }
        this.error = true
        this.isAlertVisible = true
      })
    },
  },

  setup() {
    const date = ref(null)
    const menu = ref(false)
    const picker = ref(null)
    const menuref = ref(null)

    const save = d => {
      menuref.value.save(d)
    }

    const formatAmount = amount => intToCurrency(store.state.user.lang, {
      style: 'currency',
      currency: 'XAF',
      minimumFractionDigits: 0,
    }, amount)

    return {
      formatAmount,
      save,
      date,
      menu,
      picker,
      menuref,
      headers: [
        { text: 'REFERENCE', value: 'reference' },
        { text: 'ID TRANSACTION', value: 'transaction_id' },
        { text: 'MOTIF', value: 'pay_reason.label' },
        { text: 'INITIATEUR', value: 'user' },
        { text: 'COMPTE', value: 'svc_number' },
        { text: 'MONTANT', value: 'base_amount' },
        { text: 'MONTANT HORS FRAIS', value: 'amount' },
        { text: 'CHAUFFEUR', value: 'road.user' },
        { text: 'MOYEN DE PAIMENT', value: 'op_api.operator.label' },
        { text: 'TYPE DE PAIEMENT', value: 'payment_type.label' },
        { text: 'DATE DE CREATION', value: 'created_at' },
        { text: 'ACTIONS', value: 'actions' },
      ],
      itemsPerPage: parseInt(store.state.roadPayment.field.paginate, 10),

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiAccountLock,
        mdiLockOpen,
        mdiEyeOutline,
        mdiRefresh,
        mdiCashMinus,
        mdiCashPlus,
      },
    }
  },
}
</script>

<style scoped>

</style>
